
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











































.personality-moodboard-inner {
  @include mq(s) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: col(1, 10);
  }

  @include mq(l) {
    padding: 0 col(1, 12);
  }
}

.personality-moodboard__picture {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: $spacing;
  border-radius: 1.5rem;
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.15);

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  ::v-deep img {
    @include image-fit;
  }

  @include mq(s) {
    flex: 1;
    margin-bottom: 0;
  }
}

.personality-moodboard__content {
  font-size: 1.5rem;
  line-height: 2.5rem;

  @include mq(s) {
    flex: 1;
  }
}
